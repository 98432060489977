import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/cancellation.scss"
import { MDXRenderer } from "gatsby-plugin-mdx"


const Cancellation = ({data}) => (
  <Layout>
    <SEO title="Cancellation" />
    <div className="cancellation-page">
        <div className="Header-parent">
          <MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
        </div>
        <div className="page-content">
            <div className="steps-parent">
            <MDXRenderer>{data.Steps.nodes[0].body}</MDXRenderer>
            </div>
            <div className="cancellation-parent">
            <MDXRenderer>{data.Cancellation.nodes[0].body}</MDXRenderer>
            </div>
        </div>
    </div>
    </Layout>
)
export const pageQuery = graphql`
query CancellationQuery {
  Header: allMdx(filter: {frontmatter: {title: {eq: "Header"}}}) {
    nodes{
      body
    }
  }
  Steps: allMdx(filter: {frontmatter: {title: {eq: "Steps"}}}) {
    nodes{
      body
    }
  }
  Cancellation: allMdx(filter: {frontmatter: {title: {eq: "Cancellation"}}}) {
    nodes{
      body
    }
  }
}

`
export default Cancellation
